module.exports = {
    language: {
        index: 'index',
        productCenter:'Product Center',
        newsTendency:'News Tendency',
        aboutUs:'About Us',
        officialMall:'Official Mall',
        telephone:'Telephone',
        addressTitle:'Address',
        addressDetail:'Jiu Qu Jie Dao Chu Jia Zhuang, Hedong District, Linyi City, Shandong Province',
        emailTitle:'Email',
        wechatTitle:'Wechat',
        addQQ:'Scan code and add QQ friends',
        addwechat:'Scan code and add wechat friends',
        detailTitle:'Detail',
        moreTitle:'More',
        elegantTitle:'Company Style',
        activeTitle:'Company Dynamics',
        noticeTitle:'Notice Announcement',
        tradeTitle:'Industry News',
        contactTitle:'Contact Us',
        name:'Name',
        phone:'Telephone',
        email:'Email',
        address:'Address',
        words:'words',
        submit:'submit'
    }
}