import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'index',
      component: resolve => require(['@/page/index.vue'], resolve),
      children: [
        {
          path: "/index",
          name: '/index',
          component: resolve =>
            require(["@/page/components/index.vue"], resolve)
        },
        {
          path: "/product",
          name: '/product',
          component: resolve =>
            require(["@/page/components/product.vue"], resolve)
        },
        {
          path: "/news",
          name: '/news',
          component: resolve =>
            require(["@/page/components/news.vue"], resolve)
        },
        {
          path: "/about",
          name: '/about',
          component: resolve =>
            require(["@/page/components/about.vue"], resolve)
        }
      ]
    }
  ]
})