module.exports = {
    language: {
        index: '首页',
        productCenter:'产品中心',
        newsTendency:'动态新闻',
        aboutUs:'关于我们',
        officialMall:'官方商城',
        telephone:'联系电话',
        addressTitle:'公司地址',
        addressDetail:'山东省临沂市河东区九曲街道褚家庄',
        emailTitle:'邮箱',
        wechatTitle:'微信',
        addQQ:'扫码加QQ好友',
        addwechat:'扫码加微信好友',
        detailTitle:'查看详情',
        moreTitle:'查看更多',
        elegantTitle:'公司风采',
        activeTitle:'公司动态',
        noticeTitle:'通知公告',
        tradeTitle:'行业新闻',
        contactTitle:'联系我们',
        name:'姓名',
        phone:'电话',
        email:'邮箱',
        address:'地址',
        words:'留言',
        submit:'提交'
    }
}